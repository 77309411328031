import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CurrencyFormatPipe } from './currencyFormat/currency-format.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CurrencyFormatPipe],
  exports: [CurrencyFormatPipe],
})
export class AppSharedUiCurrencyModule {}
